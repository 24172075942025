<template>
  <v-card>
    <v-card-title>入金消込</v-card-title>
    <v-card-subtitle class="px-6" style="font-size:1rem;">
      <v-row>
        <v-col cols="4">
          <div>
            <span style="font-weight:bold;">入金ID: </span>
            <span>{{ selectedDeposit.depo_id }}</span>
          </div>
          <div>
            <span style="font-weight:bold;">入金金額: </span>
            <span :style="isEqual ? '' : 'color:red;'">{{ formatData(selectedDeposit.depo_amount) }}</span>
            <span v-if="!isEqual" :style="'color:red;'">(金額を確認してください！)</span>
          </div>
          <div>
            <span style="font-weight:bold;">顧客: </span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <a
                  v-bind="attrs"
                  href="javascript:void(0);"
                  :style="'text-decoration: underline; color:#696969' "
                  v-on="on"
                >{{ selectedDeposit.cust_id }}</a>
              </template>
              <span>1</span><br>
              <span>2</span><br>
              <span>3</span>
            </v-tooltip>
          </div>
        </v-col>
        <v-col cols="8">
          <div>
            <span style="font-weight:bold;">入金日: </span>
            <span>{{ selectedDeposit.depo_date }}</span>
          </div>
          <div>
            <span style="font-weight:bold;">入金口座: </span>
            <span>{{ selectedDeposit.depo_account_id }}</span>
          </div>
        </v-col>
      </v-row>
    </v-card-subtitle>

    <v-divider></v-divider>

    <v-card-title>入金操作</v-card-title>
    <v-card-text>
      <v-data-table
        v-model="selectList"
        :headers="Headers"
        :items="tableList"
        show-select
        item-key="inv_id"
        :items-per-page="-1"
        hide-default-footer
        style="border:thin solid rgba(0, 0, 0, 0.14)"
        class="elevation-0"
      >
        <template v-slot:[`item.price`]="{ item }">
          {{ formatData(item.price) }}
        </template>
        <template v-slot:[`body.append`]>
          <tr>
            <td
              class="font-weight-bold"
              :colspan="2"
              style="background: #fafafa"
            >
              <label>操作合計</label>
            </td>
            <td
              class="font-weight-bold"
              style="text-align: right; background: #fafafa"
            >
              <label>{{ formatData(totalPrice) }}</label>
            </td>
            <td
              style="background: #fafafa"
              :colspan="2"
            ></td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        outlined
        elevation="0"
        @click="goBack"
      >
        <span>戻る</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  mapActions, mapMutations, mapState,
} from 'vuex'

export default {
  components: {
  },
  data: () => ({
    Headers: [
      {
        text: 'Inv_id',
        align: 'left',
        sortable: false,
        width: '7%',
        value: 'inv_id',
      },
      {
        text: '金額',
        align: 'right',
        sortable: false,
        width: '38%',
        value: 'price',
      },
      {
        text: '入金予定日',
        align: 'right',
        sortable: false,
        width: '50%',
        value: 'date',
      },
      {
        text: '　',
        align: 'right',
        sortable: false,
        width: '5%',
      },
    ],
    tableList: [
      {
        inv_id: 1,
        price: 10000,
        date: '2023-05-31',
      },
      {
        inv_id: 2,
        price: 5000,
        date: '2023-05-31',
      },
      {
        inv_id: 3,
        price: 16000,
        date: '2023-05-31',
      },
      {
        inv_id: 4,
        price: 15000,
        date: '2023-05-31',
      },
      {
        inv_id: 5,
        price: 2000,
        date: '2023-06-01',
      },
      {
        inv_id: 6,
        price: 1000,
        date: '2023-06-01',
      },
      {
        inv_id: 7,
        price: 100000,
        date: '2023-06-01',
      },
    ],
    selectList: [],
  }),
  computed: {
    ...mapState('depositStore', ['selectedDeposit']),
    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },

    isEqual() {
      if (this.selectedDeposit.depo_amount >= this.totalPrice) {
        return true
      }

      return false
    },

    totalPrice() {
      let sum = 0
      if (this.selectList) {
        this.selectList.forEach(a => {
          sum += a.price
        })
      }

      return sum
    },
  },
  watch: {
  },
  created() {
    this.loadData()
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('depositStore', ['loadDeposit']),

    goBack() {
      this.$router.push({
        name: 'deposit-list',
      })
    },

    loadData() {
      this.setOverlayStatus(true)
      this.loadDeposit(this.$route.params.id).finally(() => {
        this.setOverlayStatus(false)
        console.log('selectedDeposit', this.selectedDeposit)
      })
    },

  },
}
</script>

<style scoped>
.theme--light.v-data-table >>> th {
  border-top: 0;
}
</style>
